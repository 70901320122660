<template>
  <IconListItem icon="orders" :label="$t('myorders.listitem.plays.label')">
    {{ value }}
  </IconListItem>
</template>

<script lang="ts" setup>
import type {
  AddOnOutput,
  LotteryId,
} from '~/@types/generated/backend/graphql-schema-types'

import IconListItem from '~/components/IconList/IconListItem.vue'
import { getNormalizedAddOns } from '~/utils/addons'

type MyOrdersListItemPlayProps = {
  addOns: (AddOnOutput | string)[]
  lotteryId: LotteryId
  numberOfPlays: number
}

const props = defineProps<MyOrdersListItemPlayProps>()

const { t } = useI18n()

const translatedAddOns = computed(() =>
  getNormalizedAddOns(props.addOns).map((addOn) =>
    t(`lottery.${props.lotteryId}.${addOn}`),
  ),
)

const translatedNumberOfPlays = computed(() =>
  t('myorders.listitem.plays.value', props.numberOfPlays),
)

const value = computed(() =>
  translatedAddOns.value.length
    ? `${translatedNumberOfPlays.value} + ${translatedAddOns.value.join(' + ')}`
    : translatedNumberOfPlays.value,
)
</script>
