import type { AddOnOutput } from '~/@types/generated/backend/graphql-schema-types'

const isAddonOutput = (addon: AddOnOutput | string): boolean =>
  !!(addon as AddOnOutput).name && !!(addon as AddOnOutput).value

export const getNormalizedAddOns = (
  addOns: (AddOnOutput | string)[],
): string[] =>
  addOns.map((addOn) =>
    isAddonOutput(addOn) ? (addOn as AddOnOutput).name : (addOn as string),
  )
