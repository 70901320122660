<template>
  <LcButton v-bind="{ size }" :loading="isStoring" @click="replayNumbers">
    {{ buttonLabel }}
  </LcButton>
</template>

<script lang="ts" setup>
import { LicensedTerritory } from '@lottocom/lottery-mapping'

import type { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

type MyOrdersReplayButtonProps = {
  lotteryId?: Maybe<LotteryId>
  playSlipOrderId?: Maybe<string>
  size?: 'medium' | 'small'
}

const props = withDefaults(defineProps<MyOrdersReplayButtonProps>(), {
  size: 'medium',
})

const licensedTerritory = useLicensedTerritory({ raw: true })
const { t } = useI18n()

const { mutate } = useReplayPlaySlipMutation()
const { addToast, addToastError } = useToaster()

const isStoring = ref(false)

const buttonLabel = computed(() =>
  licensedTerritory === LicensedTerritory.US_NY
    ? t('myorders.replaybutton.ny.label')
    : t('myorders.replaybutton.label'),
)

const replayNumbers = async () => {
  if (!props.lotteryId || !props.playSlipOrderId) return
  isStoring.value = true

  try {
    const result = await mutate({
      licensedTerritory,
      playSlipOrderId: props.playSlipOrderId,
    })

    if (!result?.data) return
    useCachedProduct(props.lotteryId).setProductId(
      result.data.replayPlaySlip.id,
    )

    await navigateTo({
      path: '/checkout',
      query: { p: props.lotteryId.toLowerCase() },
    })
  } catch (error) {
    if (
      isSpecificGraphQLError(error, {
        matchMessage: 'validation.playslip.duplicate',
      })
    ) {
      addToast({
        message: t('validation.playslip.duplicate', {
          lottery: t(`lottery.${props.lotteryId}`),
        }),
        tracking: {
          action: 'checkout',
          topicKey: 'replay_playslip_validation_error',
        },
        type: 'info',
      })
      usePlaySlip(props.lotteryId).reset()
      navigateTo(`/${props.lotteryId.toLowerCase()}`)
      return
    }

    addToastError({
      error,
      prefix: 'myorders.replay.toast.error',
      tracking: {
        action: 'checkout',
        topicKey: 'replay_playslip_failed',
      },
    })
  } finally {
    isStoring.value = false
  }
}
</script>
